<template>
  <van-popup v-model="show" round position="bottom" class="custom-popup" closeable
  close-icon-position="top-right" @close="close" :style="{width: width + 'px', marginLeft: -width / 2 + 'px'}">
    <h4 class="title">{{title}}</h4>
    <div class="modal-content">
      <slot></slot>
      <slot name="footer">
        <van-button type="info" class="submit-btn" @click="confirm">确认</van-button>
      </slot>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: "custom-popup",
  props: {
    show:{
      type:Boolean,
    },
    title: {
      type: String,
    }
  },
  data() {
    return {
      width: 0,
    };
  },
  watch: {
    show(newValue, oldValue) {
      if (newValue != oldValue) {
        this.width = document.body.clientWidth;
      }
    }
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm");
    }
  },
};
</script>

<style lang="less" >
  @import "../assets/less/const";
  .custom-popup {
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #002044;
    overflow-y: initial;
    left: 50%;
    .title {
      background-image: url(../assets/img/modal-title.png);
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      width: 450px;
      height: 148px;
      position: absolute;
      line-height: 130px;
      font-size: 40px;
      text-align: center;
      top: -38px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99;
    }
    .van-popup__close-icon {
      font-size: 40px;
      color: #fff;
    }
    .modal-content {
      padding: 166px 40px 40px 40px;
      .sub-title {
        font-size: 36px;
        margin-bottom: 44px;
        display: flex;
        align-items: center;
      }
      .submit-btn {
        width: 600px;
        height: 108px;
        margin: 60px auto 0 auto;
        display: block;
        font-size: 36px;
        background: rgba(41,111,168,0.21);
        color: #00F1FF;
        font-size: 36px;
        .gradient-border();
        &.van-button--disabled {
          background: rgba(83,83,83,0.21);
          color: #6B6D73;
          border-image: -webkit-linear-gradient(#758285, #3C464E) 10 10;
          border-image: -moz-linear-gradient(#758285, #3C464E) 10 10;
          border-image: -o-linear-gradient(#758285, #3C464E) 10 10;
          border-image: linear-gradient(#758285, #3C464E) 10 10;
        }
      }
    }
  }
</style>

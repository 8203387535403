<template>
  <div class="choose-example main-page">
    <div class="header">
      <span class="icon-block" @click="goBack">
        <van-icon name="arrow-left" />
        <span>返回</span>
      </span>
      <h4>{{title}}</h4>
    </div>
    <div class="message-tip">
      <van-icon name="warning" />
      <span>提示：{{ tipObj[type] }}</span>
    </div>
    <ul class="example-list clearfix" :style="{height: exampleHeight + 'px'}">
      <li :class="[(uploadFileUrl && uploadFileUrl == fileUrl) ? 'choose' : '', uploadFileUrl ? 'has-data' : '']" @click="chooseCustom" >
        <van-uploader v-model="fileList" :max-count="1" :before-read="beforeRead" :accept="accept" />
        <div class="custom-img" v-show="uploadFileUrl">
          <video
            :src="uploadFileUrl"
            @loadeddata="captureImage"
            autoplay
            x5-playsinline=""
            playsinline=""
            webkit-playsinline=""
            ref="videoRef"
            v-show="type == 'video'"
          > 您的浏览器不支持 video 标签。</video>
          <img :src="uploadFileUrl" alt="" class="main-cover" v-if="type != 'video'" />
          <img src="@/assets/img/icon-add.png" class="icon-add" />
        </div>
        <div class="img-tip" v-show="!uploadFileUrl">
          <img :src="['video', 'videoAttack'].includes(type) ? imgVideo : imgPic" class="icon-picture" />
          <p>{{ fontTip }}</p>
        </div>
      </li>
      <li :class="fileUrl == item.url ? 'choose' : ''" v-for="(item, index) in editDataList" :key="index" @click.stop="onChoose(item)">
        <van-image fit="cover" lazy-load :src="type == 'video' ? item.coverUrl : item.url" alt="" class="main-img" v-if="type != 'videoAttack'">
          <template v-slot:loading>
            <van-loading class="fs-80 lazy-loading" color="#007BFF"/>
          </template>
        </van-image>
        <video v-else :src="item.url" style="object-fit:contain"  x5-playsinline="" playsinline="" webkit-playsinline="" x5-video-player-type="h5" muted x5-video-player-fullscreen="" ref="mediaListRef" loop x5-video-orientation="portraint">您的浏览器不支持 video 标签。</video>
        <div class="file-name">{{item.fileName}}</div>
      </li>
    </ul>

    <van-button type="info" class="choose-btn" v-if="fileUrl && !['fakeFace', 'videoAttack'].includes(type)" @click="onDetect">{{type == 'integratedMachine' ? '确认' : (type == 'forgeTrace' ? '立即检测' : '开始检测')}}</van-button>

    <custom-popup :show="forgeTraceShow" title="溯源检测方式" @close="forgeTraceShow = false" class="forge-dialog" @confirm="onConfirm">
      <van-radio-group v-model="groupRadio">
        <van-radio name="1" shape="square"> 换脸类型伪造溯源</van-radio>
          <div class="checkbox-detail">将目标人脸替换到视频中其他人脸上的伪造方法类型溯源；包括deepfake，deepfacelab，faceswap等常见伪造方法。</div>
        <van-radio name="2" shape="square">重演类型伪造溯源</van-radio>
        <div class="checkbox-detail"> 修改视频中的人脸姿态，不改变原人物身份的伪造方法溯源；包括Face2Face, First Order Motion, NeuralTextures等常见伪造方法。</div>
        <van-radio name="0" shape="square">全类型伪造溯源</van-radio>
        <div class="checkbox-detail"> 包括库内30+种方法的全类型伪造溯源，包括人脸换脸、人脸重演、人脸合成和人脸编辑等不同类型的深度伪造方法。</div>
    </van-radio-group>
    </custom-popup>
  </div>
</template>

<script>
import CustomPopup from "@/components/CustomPopup.vue";
import { DATA, DEFENSE } from "@/remote";
import { Toast } from "vant";

const FILE_TYPE_OBJ = {
  'deepImg': 1, // 深度合成图像样例库
  'video': 2, // 深度合成视频样例库
  'contract': 4, // 合同证件样例库
  'travelCode': 6, // 行程码样例库
  'doc': 5, // 文档类样例库
  'img': 9, // 自然图像样例库
  'forgeTrace': 7, // 伪造溯源样例库
  'integratedMachine': 8, // 一体机图片样例库
  'idCard': 3, // 身份证样例库
  'bankCard': 10, // 银行卡样例库
  'fakeFace': 11, // 身份认证伪造
  'videoAttack': 12, // 音视频伪造
}

const TIP_OBJ = {
  deepImg: "请上传高清图片，图片大小不超过5M，小于200k将影响检测质量",
  video: "请上传包含正面人脸的短视频，视频大小不超过100M",
  contract: "示例均非真实材料，仅作体验用途；请上传清晰完整的合同证件图片，图片大小不超过5M",
  travelCode: "请上传清晰完整的行程码图片，图片大小不超过5M",
  doc: "请上传清晰的一般文档类图片，图片大小不超过5M",
  img: "请上传清晰的高质量图片，图片大小不超过5M",
  forgeTrace: "请上传伪造图片进行溯源检测，图片大小不超过5M",
  integratedMachine: "请上传清晰的高质量图片，图片大小不超过5M",
  idCard: "示例均非真实证件，仅作体验用途；请上传清晰完整的身份证图片，图片大小不超过5M",
  bankCard: "示例仅作体验用途；请上传清晰完整的银行卡图片，图片大小不超过5M",
  fakeFace: "请上传清晰的单人正脸图片，图片大小不超过5M",
  videoAttack: "请上传清晰的单人正脸短视频，视频大小不超过10M",
};
export default {
  name: "choose-example",
  props: {
    dataList: {
      type: Array,
    },
    type: {
      type: String,
    },
  },
  components: {
    CustomPopup
  },
  data() {
    return {
      title: '选择检测内容',
      editDataList: [],
      accept: 'image/*',
      fileUrl: '',

      imgPic: require('@/assets/img/icon-picture.png'),
      imgVideo: require('@/assets/img/icon-video.png'),

      fileList: [],
      uploadFileUrl: "",
      fontTip: "添加图片",
      videoImgUrl: "",
      tipObj: TIP_OBJ,

      forgeTraceShow:false,
      groupRadio: "0",
      exampleHeight: 0,
    };
  },
  mounted() {
    this.accept = ['video', 'videoAttack'].includes(this.type) ? "video/*" : "image/*";
    this.fontTip = ['video', 'videoAttack'].includes(this.type) ? "添加视频" : "添加图片";

    if (['fakeFace', 'videoAttack'].includes(this.type)) {
      this.title = this.type == 'fakeFace' ? '选择照片' : '选择视频'
    }

    // document.body.style.overflow = 'hidden'

    if (this.type == 'video') {
      this.editDataList = this.dataList.map((data, index) => ({
        ...data,
        url: data.videoUrl,
        fileName: this.getFileName(data.coverUrl, index)
      }))
    } else {
      this.editDataList = this.dataList.map((data, index) => ({
        url: data,
        fileName: this.getFileName(data, index)
      }))
    }

    // 获取案例区域高度
    setTimeout(() => {
      let fontSize = Number(document.documentElement.style.fontSize.replace('px', ''));
      this.exampleHeight = document.querySelector('.choose-example').scrollHeight - document.querySelector('.example-list').offsetTop - 0.444 * fontSize;
      this.$refs.mediaListRef.map(item => item.play());
    }, 100)

  },

  destroyed() {
    document.body.style.overflow = "auto";
  },

  methods: {
    getFileName(url = '', index) {
      // 一体机特殊处理
      if (['integratedMachine', 'fakeFace', 'videoAttack', 'bankCard'].includes(this.type)) {
        return `样例${index + 1}`
      }
      let _index = url.lastIndexOf('/');
      return url.substring(_index + 1).split('.')[0];
    },

    goBack() {
      this.$emit("goBack");
    },

    // 选择示例
    onChoose(item) {
      if (['fakeFace', 'videoAttack'].includes(this.type)) {
        this.$emit("onDetect", item.url);
        return;
      }
      this.fileUrl = item.url;
    },

    // 选择本地图片
    chooseCustom() {
      this.fileUrl = this.uploadFileUrl || this.fileUrl;
    },

    onConfirm() {
      this.$emit("onDetect", this.fileUrl, this.groupRadio);
    },

    // 立即检测
    onDetect() {
      if(this.type == "forgeTrace"){
        this.forgeTraceShow = true;
        return;
      }
      if (!this.fileUrl) {
        return;
      }
      this.$emit("onDetect", this.fileUrl);
    },

    captureImage() {
      // let video = this.$refs.videoRef;
      // console.log(9999, video)
      // let canvas = document.createElement('canvas');
      // let ctx = canvas.getContext('2d');
      // canvas.width = video.videoWidth
      // canvas.height = video.videoHeight
      // ctx.drawImage(video, 0, 0);
      // this.videoImgUrl = canvas.toDataURL();
    },

    // 上传
    beforeRead(file) {
      if (['video', 'videoAttack'].includes(this.type)) {
        if (file.type !== "video/mp4") {
          // Toast('请上传MP4的视频！');
          // return false;
        }
        const isLt100M = file.size / 1024 / 1024 <= 100;
        const isLt10M = file.size / 1024 / 1024 <= 10;
        if (!(this.type == 'video' ? isLt100M : isLt10M)) {
          Toast(`视频大小不能超过${this.type == 'video' ? 100 : 10}MB！`);
          return false;
        }
      } else {
        const isJpgOrPng =
          file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
          Toast("请上传JPG，PNG的图片！");
          return false;
        }
        const isLt5M = file.size / 1024 / 1024 <= 5;
        if (!isLt5M) {
          Toast("图片大小不能超过5MB！");
          return false;
        }
      }

      let formData = new FormData();
      formData.append("pic", file);
      let API = ['fakeFace', 'videoAttack'].includes(this.type) ? DEFENSE : DATA;
      API.uploadFile(formData, FILE_TYPE_OBJ[this.type]).then((res) => {
        if (res.code == 200) {
          if (['fakeFace', 'videoAttack'].includes(this.type)) {
            this.$emit("onDetect", res.data);
            return;
          }
          this.uploadFileUrl = res.data;
          this.fileUrl = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less">
@import "../assets/less/chooseExample";
</style>

